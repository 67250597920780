import { all } from "redux-saga/effects";

import user from "./user";
import fieldData from "./fieldData";
import webPush from "./webPush";
import email from "./email";
import sms from "./sms";
import smsTree from "./smsTree";
import chatBot from "./chatBot";
import onsite from "./onsite";
import socialSharing from "./socialSharing";
import cpa from "./cpa";
import analytics from "./analytics";
import media from "./media";
import promoCodes from "./promoCodes";
import simNumbers from "./simNumbers";
import campaignOffer from "./campaignOffer";

function* rootSaga() {
  yield all([
    ...user(),
    ...fieldData(),
    ...webPush(),
    ...email(),
    ...sms(),
    ...smsTree(),
    ...chatBot(),
    ...onsite(),
    ...socialSharing(),
    ...cpa(),
    ...analytics(),
    ...media(),
    ...promoCodes(),
    ...simNumbers(),
    ...campaignOffer(),
  ]);
}

export default rootSaga;
