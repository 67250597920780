import axios from "./axios";
import {
  FETCH_ANALYTICS_ENDPOINT,
  FETCH_RETENTION_ENDPOINT,
  FETCH_METRICS_FILTER,
  FETCH_METRICS_DETAILS,
  FETCH_ANALYTICS_HEATMAP,
  FETCH_ANALYTICS_SEPARATE,
} from "../constants/endpoints";

const convertFiltersToQueryString = (filters) => {
  let queryString = '';
  for (const key in filters) {
    if (filters[key].length > 0) {
      queryString += `&filters[${key}][]=`;
      queryString += filters[key].join(`&filters[${key}][]=`);
    }
  }
  return queryString;
};

const fetchAnalyticsSeparate = async (type, dateFrom, dateTo, filters) => {
  let queryString = '';
  if (filters) {
    queryString = convertFiltersToQueryString(JSON.parse(filters));
  }
  const { data: list } = await axios.get(
    `${FETCH_ANALYTICS_SEPARATE}?type=${type}&from=${dateFrom}&to=${dateTo}${
      queryString ? `&id=${queryString}` : ''
    }`,
  );

  return list;
};

const fetchAnalytics = async (authHeader, dateFrom, dateTo, filters) => {
  let queryString = '';
  if (filters) {
    queryString = convertFiltersToQueryString(JSON.parse(filters));
  }
  const { data: list } = await axios.get(
    `${FETCH_ANALYTICS_ENDPOINT}?from=${dateFrom}&to=${dateTo}${
      queryString ? `&id=${queryString}` : ''
    }`,
  );

  return list;
};

const fetchRetentionRateChart = async (dateFrom, dateTo) => {
  const { data: list } = await axios.get(
    `${FETCH_RETENTION_ENDPOINT}?from=${dateFrom}&to=${dateTo}`
  );

  return list;
};

const fetchHeatmap = async (left_top_lat, left_top_lng, right_bottom_lat, right_bottom_lng) => {
  const { data: list } = await axios.get(
      `${FETCH_ANALYTICS_HEATMAP}?left_top_lat=${left_top_lat}&left_top_lng=${left_top_lng}&right_bottom_lat=${right_bottom_lat}&right_bottom_lng=${right_bottom_lng}`
  );

  return list;
};

const fetchMetricsFilters = async () => {
  const { data: filters } = await axios.get(FETCH_METRICS_FILTER);

  return filters;
};

const fetchMetricsDetails = async (dateFrom, dateTo, page, perPage, filters) => {
  let queryString = '';
  if (filters) {
    queryString = convertFiltersToQueryString(JSON.parse(filters));
  }
  const { data: details } = await axios.get(
    `${FETCH_METRICS_DETAILS}?from=${dateFrom}&to=${dateTo}&page=${page}&per_page=${perPage}${
      queryString ? `&id=${queryString}` : ''
    }`
  );

  return details;
};

const fetchDownloadReport = async url => {
  await axios.get(url, { responseType: 'blob' }).then((response) => {
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'analytics_detail_report.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export default {
  fetchAnalytics,
  fetchAnalyticsSeparate,
  fetchRetentionRateChart,
  fetchHeatmap,
  fetchMetricsFilters,
  fetchMetricsDetails,
  fetchDownloadReport,
};
