import { snakeCase, camelCase } from 'change-case';
import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';

import { analytics as api } from '../api';
import { object } from '../help';
import { adjustDateFrom } from '../help/analytics';
import {
  analytics as actions,
  snackbar as snackbarActions,
} from '../redux/actions';

import { getAuthHeader } from './selectors';

function* fetchAnalytics({ payload: { dateFrom, dateTo, filters } }) {
  try {
    const authHeader = yield select(getAuthHeader);
    const result = yield call(api.fetchAnalytics, authHeader, dateFrom, dateTo, filters);
    yield put(actions.putAnalytics(object.snakeToCamel(result)));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request analytics error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchAnalyticsSeparate({
  payload: {
    type, dateFrom, dateTo, filters,
  },
}) {
  try {
    const result = yield call(api.fetchAnalyticsSeparate, snakeCase(type), dateFrom, dateTo, filters);

    let dataToPut;
    if (type === 'piechartAll') {
      const aggregatedResults = Object.keys(result).reduce((acc, key) => {
        const mappedType = camelCase(key);
        acc[mappedType] = result[key];
        return acc;
      }, {});
      dataToPut = { result: aggregatedResults };
    } else {
      const mappedType = type;
      dataToPut = { mappedType, result };
    }

    yield put(actions.putAnalyticsSeparate(dataToPut));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request analytics separate error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchRetentionRateChart({ payload: { dateFrom, dateTo } }) {
  try {
    const adjustedDateFrom = adjustDateFrom(30, dateFrom, dateTo);
    const result = yield call(api.fetchRetentionRateChart, adjustedDateFrom, dateTo);
    yield put(actions.putRetentionRateChart(result));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request retention rate chart error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchHeatmap({
  payload: {
    left_top_lat, left_top_lng, right_bottom_lat, right_bottom_lng,
  },
}) {
  try {
    const result = yield call(api.fetchHeatmap, left_top_lat, left_top_lng, right_bottom_lat, right_bottom_lng);
    yield put(actions.putHeatmap(result));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request heatmap error occurred!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchMetricsFilters() {
  try {
    const result = yield call(api.fetchMetricsFilters);
    yield put(actions.putMetricsFilters(result));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request metrics filters!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchMetricsDetails({
  payload: {
    dateFrom, dateTo, page, perPage, filters,
  },
}) {
  try {
    const result = yield call(api.fetchMetricsDetails, dateFrom, dateTo, page, perPage, filters);
    yield put(actions.putMetricsDetails(result));
  } catch (error) {
    yield put(
      snackbarActions.enqueueSnackbar({
        message: 'Request metrics details!',
        options: { variant: 'error' },
      }),
    );
  }
}

function* fetchDownloadReport({ payload }) {
  try {
    yield call(api.fetchDownloadReport, payload);
  } catch (error) {
    console.error(error);
  }
}

export default function* watcher() {
  yield takeLatest(actions.fetchAnalytics.toString(), fetchAnalytics);
  yield takeLatest(actions.fetchAnalyticsSeparate.toString(), fetchAnalyticsSeparate);
  yield takeLatest(actions.fetchRetentionRateChart.toString(), fetchRetentionRateChart);
  yield takeLatest(actions.fetchHeatmap.toString(), fetchHeatmap);
  yield takeLatest(actions.fetchMetricsFilters.toString(), fetchMetricsFilters);
  yield takeLatest(actions.fetchMetricsDetails.toString(), fetchMetricsDetails);
  yield takeLatest(actions.fetchDownloadReport.toString(), fetchDownloadReport);
}
